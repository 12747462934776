// Re-export of ConfigData in mykomap/index above seems not to work,
// so import it directly from here:
import { ConfigData } from  "mykomap/app/model/config-schema";
import type {
  FieldDefs
} from "mykomap/app/model/data-services";
import {
  mkObjTransformer,
  Transforms as T,
} from "mykomap/obj-transformer";
import * as versions from "./version.json";

import about from "../config/about.html";
import { getPopup } from './popup';
import { InitiativeObj } from "mykomap/src/map-app/app/model/initiative";

type Row = Record<string, string|null|undefined>;
const baseUri = 'https://dev.lod.coop/dotcoop/';

const rowToObj = mkObjTransformer<Row, InitiativeObj>({
  uri: T.prefixed(baseUri).from('Identifier'),
  name: T.text('').from('Name'),
  lat: T.nullable.number(null).from('Latitude'),
  lng: T.nullable.number(null).from('Longitude'),
  manLat: T.nullable.number(null).from('Geo Container Latitude'),
  manLng: T.nullable.number(null).from('Geo Container Longitude'),
  desc: T.text('').from('Description'),
  regorg: T.nullable.text(null).from('Organisational Structure'),
  primaryActivity: T.nullable.text(null).from('Primary Activity'),
  activity: T.multi({of: T.text(''), omit: ['']}).from('Activities'),
  street: T.text('').from('Street Address'),
  locality: T.text('').from('Locality'),
  postcode: T.text('').from('Postcode'),
  countryId: T.prefixed('coun:').from('Country ID'),
  www: T.nullable.text(null).from('Website'),
  chNum: T.nullable.text(null).from('Companies House Number'),
  baseMembershipType: T.nullable.text(null).from('Membership Type'),
  within: T.nullable.text(null).from('Geo Container'),
  dotcoopSector: T.nullable.text().from('Economic Sector ID'),
  dotcoopCategory: T.nullable.text(null).from('Organisational Category ID'),
});


const fields: FieldDefs = {
  desc: 'value',
  www: 'value',
  street: 'value',
  locality: 'value',
  postcode: 'value',
  countryId: {
    type: 'vocab',
    uri: 'coun:',
  },
  dotcoopSector: {
    type: 'vocab',
    uri: 'Z7_gE:',
    titleUri: 'ui:dotcoopSector',
  },
  dotcoopCategory: {
    type: 'vocab',
    uri: 'l1cbA:',
    titleUri: 'ui:dotcoopCategory',
  },
};


export const config: ConfigData = new ConfigData({
  namedDatasets: ['dotcoop'],
  htmlTitle: 'DotCoop',
  fields: fields,
  filterableFields: [
    'countryId',
    'dotcoopSector',
    'dotcoopCategory',
  ],
  searchedFields: [
    'name', 'street', 'locality', 'postcode', 'description', 'www',
  ],
  languages: ['EN', 'ES', 'FR'],
  language: 'EN',
  vocabularies: [
    {
      type: 'json',
      id: 'essglobal',
      label: 'ESSGLOBAL 2.1',
      url: 'https://dev.data.solidarityeconomy.coop/dotcoop/vocabs.json',
    },
    {
      type: 'json',
      id: 'dotcoop',
      label: 'DotCoop',
      url: 'https://dev.data.solidarityeconomy.coop/dotcoop/dotcoop-vocabs.json',
    },
    {
      type: 'json',
      id: 'translations',
      label: 'Translations',
      url: 'translations.json',
    },
  ],
  dataSources: [
    {
      id: 'dotcoop',
      label: 'DotCoop',
      type: 'csv',
      url: 'https://dev.data.solidarityeconomy.coop/dotcoop/standard.csv',
      transform: rowToObj,
    },
  ],
  defaultLatLng: [52.476, -5.449],
  showDatasetsPanel: false,
  tileUrl: 'https:/\/tile.thunderforest.com/outdoors/{z}/{x}/{y}.png?apikey=bb1cf9a95944474da77e513385d19680',
  mapAttribution:  "Maps &copy; <a href='http:/\/www.thunderforest.com'>Thunderforest</a> & &copy; <a href='http:/\/www.openstreetmap.org/copyright'>OpenStreetMap contributors</a> | Other data <a href='http:/\/creativecommons.org/licenses/by-sa/2.0/'>CC-BY-SA</a> | Powered by <a href='https:/\/www.geoapify.com/'>Geoapify</a>",
  logo: 'logo.png',
  customPopup: getPopup,
  aboutHtml: about,
  ...versions,
});
